import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Logo from "../newNav/simplex-nav-logo.svg";
import { Link } from "react-router-dom";
import NavigationButton from "../navButton/NavigationButton";
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import AnchorButton from "../portal/AnchorButton";
import useAuthStore from "../../store/authStore";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    //padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MainNavigation() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const {isAuthenticated} = useAuthStore();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const links = ["/", "/products"];
  const icons = [<HomeIcon/>,<InventoryIcon/> ]
  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        style={{   background: '#DDF7FF'
          , width: "100%" }}
        sx={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Toolbar style={{ position: "relative" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon style={{color:'#1e255e'}}/>
          </IconButton>
          <div
            style={{
              position: "absolute",
              transform: "translateX(-50%)",
              left: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
                <div style={{display: 'flex', alignSelf:'center'}}>
          <img src={Logo} alt="simplex" />
        </div>
          </div>
          <div
            style={{
              position: "absolute",
              right: "0%",
              justifyContent: "right",
              margin: 0,
              alignItems: "right",
              paddingRight: "16px",
            }}
          >
            {isAuthenticated ? <AnchorButton/> : <NavigationButton/>}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {["HomePage", "Wholesale catalogue"].map((text, index) => (
            <Link
              key={index}
              to={links[index]}
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={handleDrawerClose}>
                  <ListItemIcon>
                    {icons[index]}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
          <a
            href="../#contact"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem disablePadding>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="About us" />
              </ListItemButton>
            </ListItem>
          </a>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </>
  );
}
