import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { Loading } from "../commons/Loading";
import useAuthStore from "../../store/authStore";

const ProtectedUserRoute = ({isAdmin}) => {
  const { isAuthenticated, isLoading, verifyAuth } = useAuthStore();

  useEffect(() => {
    verifyAuth();
  }, [verifyAuth]); 

  if (isLoading) {
    return <Loading/>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />; // Redirect to home if not authenticated
  }
  return <Outlet />;
};

export default ProtectedUserRoute;
