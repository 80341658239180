
import React from "react";
import { useCategories } from "../api/contentApi";
import CategoriesCard from "../components/categoriesCard/CategoriesCard";
import { CircularProgress } from "@mui/material";
import {
  ProductContainer,
  StyledText,
  StyledTextContainer,
} from "../components/products/styles";
import ecofriendly from "../components/products/eco-friendly-svgrepo-com.svg";
import whitelabel from "../components/products/label-svgrepo-com.svg";
import styled from "styled-components";
import { breakpoints } from "../themes/config";

const StyledDiv = styled.div`
  justify-content: center;
  padding: 50px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints[2]}px) {
    flex-direction: column;
  }
  @media (min-width: ${breakpoints[2]}px) and (max-width: 1111px) {
    flex-direction: column;
  }
`;

const Products = () => {
  const { data: categories, isLoading, error } = useCategories();

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress size={100} />
      </LoadingContainer>
    );
  }

  if (error) {
    return <ErrorMessage>Error: {error.message}</ErrorMessage>;
  }

  return (
    <ProductContainer>
      <StyledDiv>
        {categories?.map((category) => (
          <CategoriesCard key={category.id || category.name} category={category} />
        ))}
      </StyledDiv>

      <DescriptionContainer>
        <StyledTextContainer>
          <Icon src={ecofriendly} alt="eco friendly" />
          <StyledText>
            Our product range is a reflection of our commitment to quality,
            sustainability, and innovation. We offer a diverse selection of
            products, each designed with meticulous attention to detail and a
            focus on sustainability. We believe that it's possible to offer
            great products without compromising on environmental responsibility.
          </StyledText>
        </StyledTextContainer>
        <StyledTextContainer>
          <Icon src={whitelabel} alt="white label" />
          <StyledText>
            We are proud to offer white-label services, allowing businesses to
            customize our products with their branding. This service is a
            testament to our flexibility and our commitment to supporting
            businesses in their growth.
          </StyledText>
        </StyledTextContainer>
      </DescriptionContainer>
    </ProductContainer>
  );
};

export default Products;

// Styled Components
const LoadingContainer = styled.div`
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
`;

const DescriptionContainer = styled.div`
  width: 80%;
  text-align: justify;
  padding-bottom: 20px;
`;

const Icon = styled.img`
  height: 40px;
  margin-right: 30px;
`;
