import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL; 

const fetchData = async (endpoint) => {
  const response = await axios.get(`${API_URL}/api/content/${endpoint}`);
  return response.data;
};

// Fetch categories
export const useCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchData("categories"),
    staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
    cacheTime: 1000 * 60 * 10, // Keep cached data for 10 minutes
  });
};

// Fetch foamboards
export const useFoamboards = () => {
  return useQuery({
    queryKey: ["foamboards"],
    queryFn: () => fetchData("foamboards"),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};

// Fetch mountboards
export const useMountboards = () => {
  return useQuery({
    queryKey: ["mountboards"],
    queryFn: () => fetchData("mountboards"),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};

// Fetch reading aids
export const useReading = () => {
  return useQuery({
    queryKey: ["readingAids"],
    queryFn: () => fetchData("reading"),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};
