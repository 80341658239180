import React from "react";
import { StyledDiv, StyledForm } from "./style";
import "./styles.css";
import AboutUs from "../aboutUs/AboutUs";

const ContactUs = (props) => {
  return (
    <StyledDiv id="contact" className="contact-us-form">
      <AboutUs />
      <div className="row" style={{ justifyContent: "center", width: "100%" }}>
        <div className="col-md-12">
          <StyledForm className="contactus-form" method="POST">
            <input type="hidden" name="form-name" value="Simplex Contact Form" />

            <fieldset>
              <legend>
                <span className="number">1</span> About You
              </legend>

              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="user_name" aria-label="user name" />

              <label htmlFor="email">Email:</label>
              <input className="email" type="email" id="mail" name="user_email" aria-label="email" />

              <label className="password" htmlFor="password">Company Name:</label>
              <input type="text" id="password" name="company" aria-label="company" />
            </fieldset>

            <fieldset>
              <legend>
                <span className="number">2</span> Your Message
              </legend>

              <label htmlFor="bio">Message:</label>
              <textarea id="bio" name="Message" aria-label="Message"></textarea>

              <label htmlFor="job">Enquiry type:</label>
              <select id="job" name="Enquiry" aria-label="Enquiry">
                <optgroup label="Enquiry type">
                  <option value="Open an account">Open an account</option>
                  <option value="General enquiry">General enquiry</option>
                </optgroup>
              </select>
            </fieldset>

            <button className="submitButton" type="submit">
              Send
            </button>
          </StyledForm>
        </div>
      </div>
    </StyledDiv>
  );
};

export default ContactUs;
