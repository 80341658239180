import React from "react";
import {
    StyledDiv,
    StyledP,
    StyledPDiv,
    StyledLink,
    StyledA
} from "./style";
import Logo from "./simplex-nav-logo.svg";
import "./style";
import PortalButton from "./PortalButton";
import useAuthStore from "../../store/authStore";
import AnchorButton from "../portal/AnchorButton";
const Navbar = () => {
  const { isAuthenticated } = useAuthStore();
  return (
    <nav
      style={{
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        textDecoration: "none",
        height: "0px",
      }}
    >
      <StyledDiv>
        <div style={{ display: "flex", alignSelf: "center" }}>
          <StyledLink to={""}>
            <img src={Logo} alt="simplex" />
          </StyledLink>
        </div>

        <StyledPDiv>
          <StyledLink to={""}>
            <StyledP>Home</StyledP>
          </StyledLink>
          <StyledA href="/#contact">
            <StyledP>About</StyledP>
          </StyledA>
          <StyledA href="/#contact">
            <StyledP>Contact</StyledP>
          </StyledA>
          <StyledLink to={"/products"}>
            <StyledP>Catalogue</StyledP>
          </StyledLink>
        </StyledPDiv>
        {isAuthenticated ? <AnchorButton/> : <PortalButton/>}
      </StyledDiv>
    </nav>
  );
};

export default Navbar;
