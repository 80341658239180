import * as React from 'react';
// import Box from '@mui/material/Box';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import { AccountBox, Inventory } from '@mui/icons-material';
// import useAuthStore from '../../store/authStore';
import { useNavigate } from 'react-router-dom';
import { AccountBoxDiv, StyledAccountBox } from './style';

export default function AnchorButton() {
  // const [state, setState] = React.useState(false);
  // const { logout } = useAuthStore();
  const navigate = useNavigate()
  // const menuItems = [
  //   { text: "Account", icon: <AccountBox />, navigation: "/portal" },
  //   { text: "Orders", icon: <Inventory />, navigation: "/portal" },
  // ];

  // const navigation = new Map([
  //   ["Account", navigate("/portal")],
  //   ["Order", navigate("orders")]
  // ]);


  // const handleLogOut = () => {
  //   logout();
  //   navigate("/");
  //   navigate(0);
  // }

  // const toggleDrawer = (open) => (event) => {
  //   if (
  //     event &&
  //     event.type === 'keydown' &&
  //     (event.key === 'Tab' || event.key === 'Shift')
  //   ) {
  //     return;
  //   }

  //   setState(open);
  // };

  // const list = (anchor) => (
  //   <Box
  //     sx={{ width: 250 }}
  //     role="presentation"
  //     onClick={toggleDrawer(false)}
  //     onKeyDown={toggleDrawer(false)}
  //   >
  //     <List>
  //       {menuItems.map((item, index) => (
  //         <ListItem key={index} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {item.icon}
  //             </ListItemIcon>
  //             <ListItemText primary={item.text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {['Log Out'].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton onClick={handleLogOut}>
  //             <ListItemIcon>
  //               <Logout/>
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  return (
    <AccountBoxDiv >
          <StyledAccountBox sx={{ fontSize: 50}} onClick={() => { navigate("/portal") }}></StyledAccountBox>
          {/* <StyledAccountBox sx={{ fontSize: 50}} onClick={toggleDrawer(true)}></StyledAccountBox> */}

          {/* <SwipeableDrawer
            anchor="right"
            open={state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer> */}
    </AccountBoxDiv>
  );
}