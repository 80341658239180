import React from 'react'
import { useState } from 'react';
import { StyledDiv } from './style'
import TextField from '@mui/material/TextField';
import { Alert, Button, Stack } from '@mui/material';
import useAuthStore from '../../store/authStore';
import { customerUpdateUserDetails } from '../../api/userApi';

export const UserPortalDetails = () => {
  const { user, setUser } = useAuthStore();
  const [editable, setEditable] = useState(false);
  const [alert, setAlert] = useState()
  const [formData, setFormData] = useState({
    name: {value:user.name, error:false},
    companyName: {value:user.companyName, error:false},
    email: {value:user.email, error:false},
    phone: {value:user.phone, error:false},
    address: {value:user.address, error:false},
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value, error }
    }));
  };

  const handleEditUser = async (id) => {
    try {
      const hasErrors = Object.keys(formData).some((key) => formData[key].error);
      if (hasErrors) {
        setAlert({ type: "error", message: "Please fix validation errors before submitting." });
        return;
      }

      const data = {
        name: formData.name.value,
        companyName: formData.companyName.value,
        email: formData.email.value,
        phone: formData.phone.value,
        address: formData.address.value
      }

      const response = await customerUpdateUserDetails(id, data);
      console.log(response.status)

      Object.keys(formData).forEach((key) => {

      })

      if (response.status === 200) {
        console.log("store user"+ JSON.stringify(user)) // Ensure response exists before accessing status
        setUser(response.data.user); // Make sure to access user data correctly
        setEditable(false);
          setAlert({ type: "success", message: "User updated successfully!" });

      } else {
            setAlert({ type: "error", message: "Failed to update user. Please try again." });

      }
    } catch (error) {
    console.error("Failed to update user:", error);
      setAlert({ type: "error", message: "Failed to update user. Please try again." });
    }
  };
  
  const validateField = (name, value) => {
    let error = false;
    switch (name) {
      case "name":
        if (!value.trim()) {
          error = true;  // checks if the value is empty or only contains spaces
        } else {
          const words = value.trim().split(/\s+/);  // Split by one or more spaces
          if (words.length < 2 || value.trim().length < 5) {
            error = true;  // if fewer than 2 words or less than 5 characters in total
          }
        }
        break;
      case "companyName":
        if (value.trim().length < 3) error = true;
        break;
      case "email":
        if (!/^\S+@\S+\.\S+$/.test(value)) error = true;
        break;
      case "phone":
        if (!/^\d{11,15}$/.test(value)) error = true;
        break;
      case "address":
        if (value.trim().length < 5) error = true;
        break;
      default:
        break;
    }
    return error;
  };


  return (
    <StyledDiv style={ { fontFamily: "Poppins", paddingTop:'100px'}}>
      <h1 style={{paddingBottom:"20px"}}>Update User Details</h1>



      <Stack spacing={6} width={700}>
      <TextField 
  error={formData.name.error} 
  helperText={formData.name.error ? "Please enter full name" : ""}
  disabled={!editable} 
  label="Name" 
  name="name" 
  value={formData.name.value} 
  onChange={handleChange}
/>

<TextField 
  error={formData.companyName.error} 
  helperText={formData.companyName.error ? "Minimum value is 3 characters" : ""}
  disabled={!editable} 
  label="Company Name" 
  name="companyName" 
  value={formData.companyName.value} 
  onChange={handleChange}
/>

<TextField 
  error={formData.email.error} 
  helperText={formData.email.error ? "Enter valid email" : ""}
  disabled={!editable} 
  label="Email" 
  name="email" 
  value={formData.email.value} 
  onChange={handleChange}
/>

<TextField 
  style={{fontFamily:'Poppins'}}
  error={formData.phone.error} 
  helperText={formData.phone.error ? "Enter valid mobile or landline" : ""}
  disabled={!editable} 
  label="Phone" 
  name="phone" 
  value={formData.phone.value} 
  onChange={handleChange}
/>

<TextField 
  style={{fontFamily:'Poppins'}}
  error={formData.address.error} 
  helperText={formData.address.error ? "Enter valid address" : ""}
  disabled={!editable} 
  label="Company Address" 
  name="address" 
  value={formData.address.value} 
  onChange={handleChange}
/>
      <Stack spacing={2} direction="row">
      {!editable && <Button variant="contained" onClick={() => setEditable(true)}>Edit</Button>}
      {editable && <Button variant="outlined" onClick={() => setEditable(false)}>Cancel</Button>}
      {editable && <Button variant="contained" onClick={() => {handleEditUser(user.id)}}>Update</Button>}
      {alert && (
        <Alert variant="filled" severity={alert.type} onClose={() => setAlert(null)}>
        {alert.message}
        </Alert>
      )}
    </Stack>
      </Stack>

    </StyledDiv>
  )
}

