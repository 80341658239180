import React from 'react';
import { useReading } from '../../../api/contentApi';
import { useParams } from 'react-router-dom';
import ReadingAidsCardNoBrowse from './ReadingAidsCardNoBrowse.jsx';
import { CircularProgress } from '@mui/material';
import { StyledDivItems } from './style.js';

const ReadingAidsDisplay = () => {
  const { data: readingAids, isLoading, error } = useReading();
  const params = useParams();

  if (isLoading) {
    return (
      <div
        style={{
          height: "900px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <CircularProgress size={100} />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          height: "900px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "red",
        }}
      >
        <p>Error: {error.message}</p>
      </div>
    );
  }

  const filteredReadingAids = readingAids
    ? readingAids.filter((readingAid) => readingAid.size === params.size)
    : [];

  return (
    <StyledDivItems>
      {filteredReadingAids.map((readingAid, index) => (
        <ReadingAidsCardNoBrowse
          key={index}
          title={readingAid.title}
          description={readingAid.description}
          imageUrl={`${readingAid.image}`}
        />
      ))}
    </StyledDivItems>
  );
};

export default ReadingAidsDisplay;
