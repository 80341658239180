import { AccountCircle } from "@mui/icons-material";
import  styled  from "styled-components";

export const StyledDiv = styled.div`
width: 80%;
height: 100%;
position: relative;
padding-top: 50px;
padding-bottom: 50px;
align-items: center;
left: 10%;
`
export const StyledAccountBox = styled(AccountCircle)`

  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background: none;
  color: #1E255E;
  :hover {
    color: #17a4d0;
    cursor: pointer; 

  }
`
export const AccountBoxDiv = styled.div`
align-self: center;
justify-self: center;
  :hover {
    cursor: pointer; 

  }
`
;