import React from 'react';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { StyledPortalButton } from './style';
import useAuthStore from '../../store/authStore';

const PortalButton = () => {
  const { login } = useAuthStore();

    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleLoginButton = async () => {
        try {
          const response = await login({email,password})
          console.log("portal response "+ response.status)
          // console.log(accessToken)
        if (response.status === 200) {
          handleClose(); // Close modal after login
          navigate("/portal");
          navigate(0)
        }
      } catch (err) {
        
      }
    };

    return (
        <>
        <StyledPortalButton onClick={handleClickOpen}>
          Portal
        </StyledPortalButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle fontFamily={"Poppins"}>Log In</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              style={{fontFamily:"Poppins"}}
              fontFamily={"Poppins"}
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              // variant="standard"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={ (e) => {
                if (e.key === "Enter")
                  handleLoginButton();
              } }

            />
            <TextField
            fontFamily={"Poppins"}
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={ (e) => {
                if (e.key === "Enter")
                  handleLoginButton();
              } }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleLoginButton} fontFamily={"Poppins"}>Login</Button>
          </DialogActions>
        </Dialog>
      </>
    );
};

export default PortalButton;

