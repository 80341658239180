import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import HomePage from './pages/home';
import Products from './pages/products';
import RootLayout from './pages/root';
import ErrorPage from './pages/Error';
import FoamboardsHome from './components/products/foamboards/FoamboardsHome';
import FoamboardsRoot from './components/products/foamboards/FoamboardsRoot';
import FoamboardsThickness from './components/products/foamboards/FoamboardsThickness';
import FoamboardsDisplay from './components/products/foamboards/FoamboardsDisplay';
import Mountboards from './components/products/mountboards/Mountboards';
import ReadingAids from './components/products/readingAids/ReadingAids';
import ReadingAidsDisplay from './components/products/readingAids/ReadingAidsDisplay';
import ReadingAidsRoot from './components/products/readingAids/ReadingAidsRoot';
import UserPortal from './pages/UserPortal';
import AdminPortal from './pages/AdminPortal';
import OrdersPage from './pages/OrdersPage';

import ProtectedUserRoute from './components/userRoutes/ProtectedUserRoutes';
import { UserPortalDetails } from './components/portal/UserPortalDetails';
// import { createTheme, ThemeProvider } from '@mui/material';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout/>,
    errorElement:<ErrorPage/>,
    children: [
      
      {index: true, element: <HomePage/>},
      {path:'/products', element: <Products/>},
      {path:'/products/Foamboards', element: <FoamboardsRoot/>, children:[
        {path:'', element: <FoamboardsHome/>},
        {path:':color', element: <FoamboardsThickness/>},
        {path:':color/:thickness', element: <FoamboardsDisplay/>},
      ]},
      {path:'/products/mountboards', element: <Mountboards/>},
      {path:'/products/readingaids', element: <ReadingAids/>, children:[
        {path:'', element: <ReadingAidsRoot/>},
        {path:':size', element: <ReadingAidsDisplay/>}
      ]},
      {
        path: "/admin",
        element: <ProtectedUserRoute isAdmin={true} />, // Protect admin dashboard
        children: [{ index: true, element: <AdminPortal /> }],
      },
      {
        path: "/portal",
        element: <ProtectedUserRoute isAdmin={false} />, // Protect admin dashboard
        children: [
          { index: false, element: <UserPortal />, children: [
            { path:'', element: <OrdersPage/> },
            { path:'/portal/details', element: <UserPortalDetails/> }


          ] },
        
        ],
      }
    ]
  }
])

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#1E255E',
//       light: '#17a4d0',
//     },
//     secondary: {
//       main: '#E0C2FF',
//       light: '#F5EBFF',
//       contrastText: '#47008F',
//     },
//   },
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 900,
//       lg: 1080, // your custom breakpoint
//       xl: 1536,
//     },
//   },
//   typography: {
//    "fontFamily": `"Poppins"`,
//    "fontSize": 14,
//    "fontWeightLight": 300,
//    "fontWeightRegular": 400,
//    "fontWeightMedium": 500
//   }
// });

function App() {

  return (
    <RouterProvider router={router}/>
  );
}

export default App;