import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "../components/navbar/MainNavigation";
import Footer from "../components/footer/Footer";
import Navbar from "../components/newNav/Navbar";
import { useMediaQuery } from 'react-responsive'; // Correct path
import "./styles.css";


const RootLayout = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 789 });
  const location = useLocation();
  const isPortalOrAdmin = location.pathname.startsWith("/portal") || location.pathname.startsWith("/admin");



  return (
    <>
      {!isPortalOrAdmin && (isSmallScreen ? <MainNavigation /> : <Navbar />)}
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
    <Outlet style={{ flexGrow: 1 }} />
    <Footer />
  </div>
    </>
      
  );
};

export default RootLayout;