import styled from "@emotion/styled";
import bannerSvg from "./banner.svg";
import bannerImageSvg from "./mountboards.jpeg";
import { Button } from "@mui/material";
export const StyledDiv = styled.div`
  width: 100%;
  height: 800px;
  background-image: url(${bannerSvg}); /* Use the imported SVG as background */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  //position: absolute;
  display: grid;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: 789px) {
    height: 800px;
  }
  @media (max-width: 430px) {
    height: 600px;

  }
`;

export const NewDiv = styled.div`
  height: 70%;
  background-image: url(${bannerImageSvg}); /* Use the imported SVG as background */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  justify-self: center;
  align-items: center;
  justify-items: center;
  justify-content: center;

  @media (max-width: 430px) {
    height: 300px;
    width: 100%;
    align-self:flex-start;
  }

  @media (min-width: 430px) and (max-width: 789px) {
    height: 60%;
    width: 100%;
    align-self:flex-start;
    margin: 60px 30px 20px 30px;

  }
`;


export const StyledHeader = styled.h1`
  color: #ffffff;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 1500;
  align-items: center;
  line-height: normal;
  align-self: center;
  justify-self: center;

  @media (max-width: 430px) {
    font-size: 26px;


  }

  @media (min-width: 430px) and (max-width: 550px) {
    font-size: 36px;
  }
`;

export const StyledP = styled.p`
  width: 405px;
  color: #ffffff;
  justify-self: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;

  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

export const StyledButton = styled(Button)`
  width: 200px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 100px;
  color: white;
  background: #17a4d0;
  border: none;
  justify-self: center;
  :hover {
    color: black;
  }
`;
