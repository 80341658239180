import React from 'react';
import { StyledDiv, StyledImg, NewDiv, StyledDivLeft, StyledHeader, StyledP, StyledButton, StyledDivRight } from './styles';
import bannerImage from "./bannerImage.webp";
import { useNavigate } from "react-router-dom";


const HeroBanner = () => {
    const navigate = useNavigate()
    return (
        <StyledDiv>
            <NewDiv>
                <StyledDivLeft>
                    <StyledHeader>
                    Visual impact, Simplified.<br/> Simplex.
                    </StyledHeader>
                    <StyledP>
                    Foam boards, Mount boards, Reading aids - presenting made easy.
                    </StyledP>
                    <StyledButton onClick={()=>{navigate("/products")}}>
                        Explore
                    </StyledButton>
                </StyledDivLeft>
                <StyledDivRight>
                    <StyledImg src={bannerImage} alt='Mount boards'/>
                </StyledDivRight>
            </NewDiv>
        </StyledDiv>
    );
};

export default HeroBanner;