import { create } from "zustand";
import Cookies from "js-cookie";
import { getCurrentUser, loginUserApi } from "../api/userApi";

const useAuthStore = create((set) => ({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    token: null,

    verifyAuth: async () => {
        set ({isLoading: true});

        try {
            const response = await getCurrentUser();
            const data  = await response.json();
            if (response.status === 200) {
                set({
                    isAuthenticated:true,
                    user: data
                })
            } else {
                Cookies.remove("jwt");
                set({ isAuthenticated: false, user: null});
            }
        } catch(error) {
            console.error("Auth verification failed:", error);
            Cookies.remove("jwt");
            set({ isAuthenticated: false, user: null });
        }
        set({ isLoading: false });

    },
    
    setUser: (user) => {
        set({ user });
    },

    login: async(credentials) => {
        try {
            const response = await loginUserApi(credentials);
            const data = await response.json();

            const { accessToken } = data
            if (!response.ok) throw new Error("Login failed");
            Cookies.set("jwt", accessToken)
            set({ token: accessToken, isAuthenticated: true });
            return response;
        } catch (error) {
            console.error("Login failed:", error);
            return false;
        }
    },

    logout: () => {
        Cookies.remove("jwt");
        set({ token: null, isAuthenticated: false });
      },
}));
export default useAuthStore;
