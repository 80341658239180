import React from 'react';
import { StyledDiv, StyledCardDiv } from './styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {  Grid } from '@mui/material';
import SpaIcon from '@mui/icons-material/Spa';
import LabelIcon from '@mui/icons-material/Label';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const HomepageCard = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('1080'));
    const mdMatches = useMediaQuery(theme.breakpoints.up('750'));

    let cardMinWidth
    let gridPortrait = false

    if (matches) {
      cardMinWidth = 200

    }
    else if (mdMatches) {
      cardMinWidth = 100

    }

    else {
      cardMinWidth = 300

      gridPortrait = true
    }

    return (

        <StyledDiv>
    <Grid spacing={3} container direction= {gridPortrait ? 'column' : 'row' } style={{textAlign: "center", alignItems: "center"}}>
        <Grid item xs={4}>
        <StyledCardDiv>
        <Card sx={{ 
      // minWidth: matches ? 200 : 100,
      minWidth: cardMinWidth,
      background: '#f4f7f8'
    }}>
      <CardContent>
        <SpaIcon/>
        <Typography variant="h5" component="div" >
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" fontFamily={"Poppins"} >
          Eco-Friendly
        </Typography>
        <Typography variant="body2" fontFamily={"Poppins"}>
        Our products are made from sustainable materials, and we strive to minimize waste in our production process.
        </Typography>
      </CardContent>
    </Card>
    </StyledCardDiv>
        </Grid>
        <Grid item xs={4}>
        <StyledCardDiv>
        <Card sx={{ 
      minWidth: cardMinWidth,
      background: '#f4f7f8',
    }}>
      <CardContent>
        <LabelIcon/>
        <Typography variant="h5" component="div">
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" fontFamily={"Poppins"}>
        White Label Services
        </Typography>
        <Typography variant="body2" fontFamily={"Poppins"}>
        Want to sell our products under your brand? We offer white label services for our wholesale customers
        </Typography>
      </CardContent>
    </Card>
    </StyledCardDiv>
        </Grid>
        <Grid item xs={4}>
        <StyledCardDiv
          >
        <Card sx={{ 
      minWidth: cardMinWidth,
      background: '#f4f7f8'
    }}>
      <CardContent>
        <PowerInputIcon />
        <Typography variant="h5" component="div">
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" fontFamily={"Poppins"}>
        Reliable Supply Chain
        </Typography>
        <Typography variant="body2" fontFamily={"Poppins"}>
        We have a reliable supply chain that ensures consistent product availability and timely delivery
        </Typography>
      </CardContent>
    </Card>
    </StyledCardDiv>
        </Grid>
      </Grid>
        </StyledDiv>
    );
};

export default HomepageCard;