import { CircularProgress } from '@mui/material'
import React from 'react'

export const Loading = () => {
  return (
          <div
          style={{
            height: "900px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress size={100} />
        </div> 
  )
}
