import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL; 

export const loginUserApi = async (credentials) => {
    try {    
        const response = await fetch(`${API_URL}/api/users/login`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
          },
        body: JSON.stringify(credentials)
    })
    console.log(" api response " + response)
    return response; // Parse JSON properly

} catch (error) {
        console.error("Error in loginUserApi:", error);
        return null; 
}
};

export const getCurrentUser = async () => {
    try {
        const response = await fetch(`${API_URL}/api/users/current`, {
            method: "GET",
            credentials: "include",
        });
        return response
    } catch (error) {
        console.error("Error in get current user api:", error);
        return null; 
    }
    
}

export const getCurrentUserOrders = async (id) => {
    try {
      const response = await axios.get(`${API_URL}/api/users/${id}/orders`, {
        withCredentials: true, // Ensures cookies are sent with the request if needed
      });
      console.log( response.data.user)
      return response.data; // Axios automatically parses JSON, no need for `.json()`
    } catch (error) {
      console.error("Error in getCurrentUserOrders API:", error);
      throw error; // Ensure React Query properly handles errors
    }
  };

  export const customerUpdateUserDetails = async (id, data) => {
    try {
      const response = await axios.put(`${API_URL}/api/users/${id}/update`, data,
        { withCredentials: true }
      );
      console.log( response)
      return response;
    } catch (error) {
      throw error;
    }
  }

export const useUserOrders = (id) => {
    return useQuery({
      queryKey: ["userOrders", id],
      queryFn: () => getCurrentUserOrders(id)
    });
  };