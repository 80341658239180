import React from "react";
import "./styles.css";
import { useMediaQuery } from "react-responsive";
import { StyledButton, StyledDiv } from "../components/HeroBanner/styles";
import MainNavigation from "../components/navbar/MainNavigation";
import Navbar from "../components/newNav/Navbar";
import Footer from "../components/footer/Footer";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 789 });
  const nav = useNavigate()
  const handleClick = () => {
    nav('/', { replace: true });
  };
  return (

    <>
      {isSmallScreen ? <MainNavigation /> : <Navbar />}
      <StyledDiv>
        <div id="ErrorMain">
          <div className="ErrorSub">
            <h1>404 Error .. Looks like you're lost</h1>
          </div>
          <StyledButton
              onClick={handleClick}
            >
              Take me back
            </StyledButton>
        </div>
      </StyledDiv>
      <Footer />
    </>
  );
};

export default ErrorPage;
