import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useAuthStore from '../store/authStore';
import { useUserOrders } from '../api/userApi';
import styled from 'styled-components';
import { Loading } from '../components/commons/Loading';


function Row(props) {

  const { row } = props
  const { date, orderAmount, orderId, orderPrice, orderStatus, products, paymentCompleted } = row;

  console.log(products)

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, fontFamily:'Poppins'}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {orderId}
        </TableCell>
        <TableCell style={{fontFamily:'Poppins'}} align="right">{formatDate(date)}</TableCell>
        <TableCell style={{fontFamily:'Poppins'}} align="right">{orderAmount}</TableCell>
        <TableCell style={{fontFamily:'Poppins'}} align="right">{orderPrice}</TableCell>
        <TableCell style={{fontFamily:'Poppins'}} align="right"><StyledSpan status={orderStatus}>{orderStatus}</StyledSpan></TableCell>
        <TableCell style={{fontFamily:'Poppins'}} align="right">{paymentCompleted ? "Paid" : "Payment due"}</TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" fontFamily={'Poppins'} >
                Order Details
              </Typography>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontFamily:'Poppins'}}>Product ID</TableCell>
                    <TableCell style={{fontFamily:'Poppins'}}>Product Name</TableCell>
                    <TableCell style={{fontFamily:'Poppins'}} align="right">Quantity</TableCell>
                    {/* <TableCell align="right">Total price ($)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product.productId}>
                      <TableCell style={{fontFamily:'Poppins'}} component="th" scope="row">
                        {product.productId}
                      </TableCell>
                      <TableCell style={{fontFamily:'Poppins'}} component="th" scope="row">
                        {product.name}
                      </TableCell>
                      <TableCell align="right">{product.quantity}</TableCell>
                      {/* <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        productQuantity: PropTypes.number.isRequired,
      }),
    ).isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

const formatDate = (isoString) => {
  return new Date(isoString).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// const fetchOrders = async (id) => {
//   const response = await getCurrentUserOrders(2);
//   if (response) {
//       const orderData = await response.json();
//       console.log(orderData);
//   }
// };

export default function OrdersPage() {
  const { user } = useAuthStore();
  const { data, isLoading, error } = useUserOrders(user.id);

  if (isLoading) return <Loading />;
  if (error) return <div>Error loading orders!</div>;

  return (
<Box sx={{ overflowX: "auto", width: "100%", padding:"100px" }}> 
<h1 style={ {fontFamily: 'Poppins', paddingBottom:'25px'} }>Order History</h1>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{fontFamily:'Poppins'}}>Order ID</TableCell>
              <TableCell style={{fontFamily:'Poppins'}} align="right">Date (Order placed)</TableCell>
              <TableCell style={{fontFamily:'Poppins'}} align="right">Order Quantity</TableCell>
              <TableCell style={{fontFamily:'Poppins'}} align="right">Order Amount (GBP)</TableCell>
              <TableCell style={{fontFamily:'Poppins'}} align="right">Shipment Status</TableCell>
              <TableCell style={{fontFamily:'Poppins'}} align="right">Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row.orderId} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const StyledSpan = styled.span`
  background-color: ${({ status }) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return "orange";
      case "processing":
        return "#ebe686";
      case "shipped":
        return "lightgreen";
      case "delivered":
        return "#40f543";
      case "cancelled":
        return "#fc5656";
      default:
        return "gray"; // Default color for unknown status
    }
  }};
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: Poppins;
`;
