import React from "react";
import { useFoamboards } from "../../../api/contentApi";
import FoamboardItem from "./FoamboardItem";
import { useParams } from "react-router-dom";
import { StyledDivItems } from "./styles";
import { CircularProgress } from "@mui/material";

const FoamboardsDisplay = () => {
  const { data: foamboards = [], isLoading, error } = useFoamboards();
  console.log(foamboards)
  const params = useParams();

  const filteredFoamboards = foamboards.filter(
    (foamboard) =>
      foamboard.thickness === params.thickness && // Convert thickness to a number
      foamboard.colour === params.color
  );

  if (isLoading) {
    return (
      <div
        style={{
          height: "900px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <CircularProgress size={100} />
      </div>
    );
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <StyledDivItems>
      {filteredFoamboards.length > 0 ? (
        filteredFoamboards.map((foamboard, index) => (
          <FoamboardItem key={index} foamboard={foamboard} />
        ))
      ) : (
        <p>No matching foamboards found.</p>
      )}
    </StyledDivItems>
  );
};

export default FoamboardsDisplay;
