import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import useAuthStore from '../store/authStore';
import Logo from "../components/newNav/simplex-nav-logo.svg"

import styled from "@emotion/styled";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "@fontsource/poppins"; // Defaults to weight 400
import "./styles.css";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const drawerWidth = 240;

export default function UserPortal() {
  const { user, logout } = useAuthStore();
  console.log(user)
  const navigate = useNavigate();
  const handleLogOut = () => {
    logout();
    navigate("/");
    navigate(0);
  }
  return (
    <div className="user-portal-container"> {/* Full height container */}
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, backgroundColor: '#ffff'}}
      >
        <Toolbar style={{ justifyContent: 'center' }}>
                  <StyledPDiv>
                    <StyledLink to={"/"}>
                      <StyledP>Home</StyledP>
                    </StyledLink>
                    <StyledA href="/#contact">
                      <StyledP>About</StyledP>
                    </StyledA>
                    <StyledA href="/#contact">
                      <StyledP>Contact</StyledP>
                    </StyledA>
                    <StyledLink to={"/products"}>
                      <StyledP>Catalogue</StyledP>
                    </StyledLink>
                  </StyledPDiv>
        </Toolbar>
      </AppBar>
      <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              height:'auto'
            },

          }}
          variant="permanent"
          anchor="left"
        >
        <Toolbar>
        <StyledLink to={""}>
            <img src={Logo} alt="simplex" />
          </StyledLink>
        </Toolbar>

        <StyledButtonUser>
          <PersonOutlineIcon/>
          <div>
          <span>{user.name}</span>
          <br/>
          <span style={{fontSize:'10px'}}>{user.companyName}</span>
          </div>


        </StyledButtonUser>
        <Divider />
        <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate('/portal')}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary='Orders' disableTypography={true}
                sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}

                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate('/portal/details')}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary='Manage' disableTypography={true}
                sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}
                />
              </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() =>  handleLogOut()}>
                <ListItemIcon>
                  <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary='Log Out' disableTypography={true}
                sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}/>
              </ListItemButton>
            </ListItem>
            <Divider />

        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >

          <Outlet/>
      </Box>
    </Box>
    </div>
  );
}

 const StyledPDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  align-items: flex-end;
`;
 const StyledLink = styled(NavLink)`
  text-decoration: none;
  align-self: flex-end;
`;

 const StyledA = styled.a`
  text-decoration: none;
  align-self: flex-end;
`;

 const StyledP = styled.p`
  font-family: "Poppins";
  padding: 0 40px;
  font-size: 18px;
  font-weight: 500;
  color: #1E255E;
  :hover{
    color: #17a4d0;
  }
`;

const StyledButtonUser = styled.button `

    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 16px 16px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Poppins;
    color: inherit;
    padding: 24px 12px 24px 16px;
    gap: 12px;
    border-radius: 12px;
`
