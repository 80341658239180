import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/authStore';


export default function NavigationButton() {
  
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { login } = useAuthStore();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLoginButton = async () => {
      try {
        const response = await login({email, password})
        console.log("portal response "+ response.status)
        // console.log(accessToken)
      if (response.status === 200) {
        handleClose(); // Close modal after login
        navigate("/portal");
        navigate(0)
      }
    } catch (err) {
      
    }
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} name="Log in" style={{color:'#1e255e', justifyContent: 'right' , padding: "0"}}>
        <LoginIcon aria-label='Log in' fontSize="medium" />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Log In</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name='email'
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name='password'
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions onKeyDown={ (e) => {
          if(e.key === 'Enter')
            handleLoginButton();
        } }>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleLoginButton}>Login</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}