import React from "react";
import { StyledDivItems, StyledIcon, StyledProduct, StyledRichText, StyledText } from "./styles";
import { useCategories, useMountboards } from "../../../api/contentApi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import MountboardsCard from "./MountboardsCard";

import { StyledHeader, StyledTextBox } from "../foamboards/styles";
import manufacturing from "../manufacturing.svg";
import ecoFriendly from "../eco-friendly-svgrepo-com.svg";
import { Loading } from "../../commons/Loading";


const Mountboards = () => {
  const { data:categories, isLoading, error } = useCategories();
  const { data:mountboards, isLoading:mbLoading, error: mbError } = useMountboards();

  if (mbLoading || isLoading) {
    return (
      <Loading/>
    )
  }
  if (error || mbError) {
    return <div>Error: {error.message}</div>; 
  }
  if (categories) {
    const category = categories.find(
      (mountboards) => mountboards.title === "Mount boards"
    );
    const { title, fullDescription, specs } = category;

    return (
        <StyledProduct>
          <StyledHeader style={{ justifySelf: "center" }}>{title}</StyledHeader>
          <StyledDivItems>
            {mountboards.map((mountboard, index) => (
            <div style={{padding:"10px"}}>
                  <MountboardsCard mountboard={mountboard} />
            </div>
            ))}
          </StyledDivItems>
          <StyledTextBox>
          <StyledIcon
            src={manufacturing}
            alt="eco friendly"
            />
          <StyledText>
            {fullDescription}
          </StyledText>
        </StyledTextBox>
        <StyledTextBox>
          <StyledIcon
            src={ecoFriendly}
            alt="eco friendly"
            />
          <StyledText>
            Our manufacturing processes prioritize waste reduction and energy efficiency, ensuring that our mount boards are produced with the utmost consideration for the planet. By choosing our mount boards, you can showcase your artwork or photographs with confidence, knowing that you are making a sustainable choice that aligns with your values.
          </StyledText>
        </StyledTextBox>
          <StyledRichText>{documentToReactComponents(specs)}</StyledRichText>
        </StyledProduct>
    );
  }
};

export default Mountboards;
